.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-header {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
}

.download-config-link {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 5px 5px;
    border-radius: 7px;
    margin: 5px;
    color:salmon;
    text-decoration: none;;
    opacity: .7;
    margin-top: 30px;
}
.download-config-link:hover {
    opacity: 1;
}

.form-category-data td {
    width: 170px;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}
.form-category-data tr {
}
.form-category-data table {
    text-align: center;
    margin: 0 auto;
    border-spacing: 0;
}

.form-button {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 10px 15px;
    border-radius: 7px;
    margin: 5px;
    text-decoration: none;;
    opacity: .7;
    margin-top: 30px;
    font-size: 24px;
    margin-bottom: 20px;
}
.form-button:hover {
    background-color: #ddd;
}

.App-link {
  color: #61dafb;
}
