html {
  height: 100%;
}
body {
  height: 100%;
}
#root {
  height: 100%;
}
.index-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.index-btn {
  display: inline-block;
  border: 1px solid rgb(73,125,63);
  padding: 5px 5px;
  border-radius: 7px;
  margin: 5px;
  color: rgb(73,125,63);
  text-decoration: none;
  opacity: .7;
  margin-top: 30px;
}
.index-link {
  display: inline-block;
  padding: 5px 5px;
  margin: 5px;
  color: rgb(73,125,63);
  opacity: .7;
  margin-top: 30px;
}
