body {
  margin: 0;
}
.selected-item {
    box-shadow: inset 0px 0px 0px 1px #bbb !important;
}
.selected-category button {
    box-shadow: inset 0px 0px 0px 1px #bbb !important;
}
.main {
    width: 100%;
}
.container {
    margin: 0 auto;
    width: 70%;
    max-width: 400px;
    min-width: 330px;
    padding: 4%;
}
.header {
}
.body {
    padding: 3%;
    padding-top: 10%;
    box-sizing: border-box;
}
.preview {
    border: 1px solid #eee;
    background-color: #fff;
    position: relative;
}
.preview::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}
.category {
    overflow: hidden;
}
.category-item {
    float: left;
}
.category-item button {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
    font-size: 1em;
    outline: none;
}
.items {
    overflow: auto;
    margin: 0 auto;
    background-color: #fff;
}
.item {
    display: inline-block;
    box-shadow: inset 0px 0px 0px 1px #eee;
}
.download-btn {
    width: 100%;
    height: 2em;
    font-size: 1.5em;
    background-color: #fff;
    border: 1px solid #eee;
}
.link {
  font-size: .8em;
  margin: 0 .2em;
}
.page-footer {
  margin-top: 1em;
}
